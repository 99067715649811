import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '~styled'
import { Modal, Button } from 'react-bootstrap'
import { Link } from '~components'

export const HeaderButtonWrapper = styled(Box)`
    .btn{
        min-width:125px;
        height: 42px;
        border-radius: 3px;
        color: var(--bs-white);
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
        display:flex;
        align-items:center;
        justify-content:center;
        padding-left:15px;
        padding-right:15px;
        letter-spacing: -0.4px;
        color:#fff;
        margin-left:10px;
        box-shadow:-12px 20px 50px rgb(80 52 252 / 30%);
        border-radius:500px;
        &:hover{
            color: #fff;
            background-image: linear-gradient(to right, rgb(208, 36, 116), rgb(163, 82, 175), rgb(95, 82, 212));
        }
        background-color: transparent;
        border: ${props => props.border ? props.border : '2px solid rgba(255, 255, 255, 0.31)'};
        box-shadow: none;
    }
    .btn-precios{
        border: 2px solid rgba(255, 255, 255, 0.31);
    }
    .btn-login{

    }
`
const ButtonLogin = ({btnLink,btnText,btnPrecios,background, path, ...rest})=>{
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <HeaderButtonWrapper {...rest}>
            <Link target="_blank" className={`btn btn-torch-red ${btnPrecios} btn-${path}`} to="https://dashboard.miempeno.com/auth/signin">
                {btnText}
            </Link>
        </HeaderButtonWrapper>
    )
}

export default ButtonLogin;