import React from 'react'
import { Link } from '~components'
import styled from 'styled-components/macro'
import { Box } from '~styled'
import { Modal, Button } from 'react-bootstrap'

export const HeaderButtonWrapper = styled(Box)`
    .btn{
        min-width:125px;
        height: 42px;
        border-radius: 3px;
        color: var(--bs-white);
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
        display:flex;
        align-items:center;
        justify-content:center;
        padding-left:15px;
        padding-right:15px;
        letter-spacing: -0.4px;
        color:#fff;
        margin-left:10px;
        box-shadow:-12px 20px 50px rgb(80 52 252 / 30%);
        border-radius:500px;
        &:hover{
            color: #fff;
            background-image: linear-gradient(to right, rgb(208, 36, 116), rgb(163, 82, 175), rgb(95, 82, 212));
        }
        background-color: transparent;
        border: 2px solid rgb(255 255 255 / 31%);
    }
    .btn-precios{
        background-image: linear-gradient(to right, rgb(208, 36, 116), rgb(163, 82, 175), rgb(95, 82, 212)) !important;
    }
`
const HeaderButton = ({btnLink,btnText,btnPrecios,...rest})=>{
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <HeaderButtonWrapper {...rest}>
            <Link className={`btn btn-torch-red ${btnPrecios}`} to={btnLink ? btnLink : "https://dashboard.miempeno.com/auth/boxed-signup"}>
                {btnText}
            </Link>
            {/* <Button onClick={handleShow} className={`btn btn-torch-red ${btnPrecios}`}>
                {btnText}
            </Button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className='p-1'>
                    <iframe src='https://dashboard.miempeno.com/auth/boxed-signup' title='website' style={{ width: '100%', height: '600px' }}></iframe>
                </Modal.Body>
            </Modal> */}
        </HeaderButtonWrapper>
    )
}

export default HeaderButton;